import React from "react";
import { Select } from "antd";

const { Option } = Select;

const renderOptionsWithImg = countries => {
  return (countries || []).map(obj => (
    <Option
      key={obj.id}
      value={obj.id}
      label={<img alt="Bandera" height={16} src={obj.urlFlag} />}
    >
      <img alt="Bandera" height={16} src={obj.urlFlag}></img>
      {"  "}
      {obj.name}
    </Option>
  ));
};

const renderOptions = countries => {
  return (countries || []).map(obj => (
    <Option key={obj.id} value={obj.id}>
      {obj.name}
    </Option>
  ));
};

export { renderOptions, renderOptionsWithImg };
