import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "react-apollo";
import { ConfigProvider } from "antd";
import esEs from "antd/lib/locale-provider/es_ES";
import Root from "./components/Root";
import * as serviceWorker from "./serviceWorker";
import Firebase, { FirebaseContext } from "./firebase";
import "moment/locale/es";
import initApollo from "./apollo/init-apollo";
import "./index.css";

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <ApolloProvider client={initApollo()}>
      <ConfigProvider locale={esEs}>
        <Root />
      </ConfigProvider>
    </ApolloProvider>
  </FirebaseContext.Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
