const brandColor = "#1890ff";
const secondaryColor = "#40a9ff";
const ternaryColor = "#f6a500";
const fourthColor = "#3E2D80";
const fiftyColor = "#171d46";
const successColor = "#32CD32";
const lightGray = "#FBFBFB";
const middleGray = "#D1D1D1";
const darkGray = "#5D5D5D";

export {
  brandColor,
  secondaryColor,
  ternaryColor,
  fourthColor,
  fiftyColor,
  successColor,
  lightGray,
  middleGray,
  darkGray,
};
