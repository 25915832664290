import React from "react";
import { compose } from "recompose";
import { Spin, message, Button } from "antd";
import { withApollo } from "react-apollo";
import { navigate } from "@reach/router";
import { verifyUserEmail } from "../../graphQl/mutations/account";
import { captureException } from "../../utils/sentry";

class CheckCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  componentDidMount() {
    const { code } = this.props;

    if (code) {
      this.props.client
        .mutate({
          mutation: verifyUserEmail,
          update: (cache, { data: { VerifyUserEmail } }) => {
            console.log("VerifyUserEmail", VerifyUserEmail);
            if (VerifyUserEmail && VerifyUserEmail.id) {
              message.success("¡Cuenta activada con éxito!");
              navigate(`/`);
            } else {
              message.error("Codigo inválido ");
              this.setState({ error: true });
            }
          },
          variables: {
            token: code,
          },
        })
        .catch(err => {
          captureException(err);
          console.log("error", err);
          message.error("Codigo inválido");
          this.setState({ error: true });
        });
    } else {
      message.error("Codigo inválido ");
      this.setState({ error: true });
    }
  }

  render() {
    const { error } = this.state;
    return (
      <div>
        {!error ? (
          <div
            style={{
              textAlign: "center",
              background: "#ffffff",
              borderRadius: "4px",
              marginBottom: "20px",
              padding: "30px 50px",
              margin: "20px 0",
            }}
          >
            <Spin />
          </div>
        ) : (
          <div style={{ width: "40rem", textAlign: "center" }}>
            <img
              src="https://chazkistorage.blob.core.windows.net/resources/logo-chazki.png"
              alt="logo"
              style={{ width: "13rem", height: "3rem", marginBottom: "7rem" }}
            />
            <div
              style={{
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "21px",
                textAlign: "center",
                color: "#109CF1",
                marginBottom: "7rem",
              }}
            >
              ¡Uy! parece que la cuenta no ha sido activada.
              Inténtalo nuevamente yendo a inicio o escríbenos a
              soporte@chazki.com
            </div>
            <Button onClick={() => navigate(`/`)} type="primary">
              Ir al inicio
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default compose(withApollo)(CheckCode);
