import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { ReadyBlockContainer } from "./styles";

class ReadyBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <ReadyBlockContainer>
        <h3>¿Listo para ser un Chazki?</h3>
        <p>Miles de rutas y paquetes te esperan para ser cubiertas. </p>
        <div className="motoCityContainer">
          <img
            className="cityImg"
            alt="city"
            src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fcity.png?alt=media&token=b049aa78-4654-4340-83b3-64bfacb6dd64"
          />
          <div className="motoImg">
            <img
              alt="city"
              src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fmoto.png?alt=media&token=d03fc0a2-f162-48fe-b6e5-d13da1bcf993"
            />
          </div>
        </div>
      </ReadyBlockContainer>
    );
  }
}

export default compose(withApollo)(ReadyBlock);
