import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { Progress, List, Icon } from "antd";
import { withGlobal } from "../../../component-global";
import { SidebarContainer } from "./styles";
// import * as route from "../../../routes/routes";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { dataForm, loadingRequest } = this.props;
    let stepProgress = 0;

    if (this.props.completeTest) {
      stepProgress = 100;
    } else {
      const totalSteps = dataForm ? dataForm.length : 0;
      stepProgress = parseInt(
        (100 *
          (dataForm.findIndex(
            obj => parseInt(obj.id, 10) === parseInt(this.props.stepID, 10)
          ) +
            1)) /
          totalSteps,
        10
      );
    }

    const formattedSteps = (dataForm || []).map(_obj => {
      return {
        title: _obj.name,
        active: this.props.completeTest ? true : this.props.stepID >= _obj.id,
      };
    });

    return (
      <SidebarContainer openSidebar={this.props.openSidebar}>
        <div style={{ width: "264px", height: "100%" }}>
          <div className="LogoChazki">
            <div className="closeButton">
              <Icon type="close" onClick={this.props.onSidebarMenu} />
            </div>
            <img
              alt="Chazki Logo"
              src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FlogoBlanco.png?alt=media&token=14977f29-4eaa-4389-9433-832fcf7be99a"
            />
          </div>
          <div className="progressContainer">
            <Progress type="circle" percent={stepProgress} />
            <p>Tu progreso</p>
          </div>
          <List
            loading={!(dataForm && dataForm.length) && loadingRequest}
            itemLayout="horizontal"
            dataSource={formattedSteps}
            renderItem={item => (
              <div className="cardStep">
                <div className="iconCheck">
                  {item.active ? (
                    <Icon
                      type="check-circle"
                      style={{ fontSize: 24, color: "#69C0FF" }}
                    />
                  ) : (
                    <div className="grayCircle" />
                  )}
                </div>
                <span style={{ display: "block" }}>{item.title}</span>
              </div>
            )}
          />
        </div>
      </SidebarContainer>
    );
  }
}

const WraperSidebar = withGlobal(Sidebar);

export default compose(withApollo)(WraperSidebar);
