import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { JumbotronContainer } from "./styles";

class Jumbotron extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <JumbotronContainer>
        <div className="backgroundRounded">
          <div className="circleContainer">
            <div className="circleGreen">
              <img
                alt=""
                src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FchazkiTeam.png?alt=media&token=136e5b62-9415-45c0-8e5f-bbe4e89ca9cb"
              />
            </div>
          </div>

          <div className="titleJumbotron">
            <h1>¡Conviértete en el Chazki de las pistas!</h1>
            <h2>
              Conviértete en un repartidor logístico <br />
              de última milla con Chazki.
            </h2>
          </div>
        </div>
      </JumbotronContainer>
    );
  }
}

export default compose(withApollo)(Jumbotron);

/*
<JumbotronContainer>
        <div className="circleContainer">
          <div className="circleGreen">
            <img
              alt=""
              src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FchazkiTeam.png?alt=media&token=136e5b62-9415-45c0-8e5f-bbe4e89ca9cb"
            />
          </div>
        </div>
        <div className="backgroundRounded">
          <div className="titleJumbotron">
            <h1>Conviértete en el Chazki de las pistas</h1>
            <h2>
              Aumenta tus ingresos como repartidor <br />
              logístico de última milla a demanda
            </h2>
          </div>
        </div>
      </JumbotronContainer>

*/