import gql from "graphql-tag";

const getAllSteps = gql`
  query getAllSteps {
    AllSteps: getAllSteps {
      id
      name
      cityID
      stepNumber
      City {
        id
        name
        countryID
      }
    }
  }
`;

const getFormByStep = gql`
  query getFormByStep($affiliateID: String, $stepID: Int) {
    FormByStep: getFormByStep(affiliateID: $affiliateID, stepID: $stepID) {
      id
      name
      description
      proccessBD
      fields {
        fields {
          order
          type
          name
          label
          searchValues
          required
          resources {
            description
            url
            title
          }
          inputSelectValues {
            type
            subtype
            subclass 
            extra{
              documentName
            }           
          }
          table
          groupName
          groupDescription
          value
          idDB
          dataType
        }
      }
    }
  }
`;

export { getFormByStep, getAllSteps };
