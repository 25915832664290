import gql from "graphql-tag";

const getAffiliateByID = gql`
  query getAffiliateByID($userID: ID!) {
    AffiliateByID: getAffiliateByID(userID: $userID) {
      stepID
      completeTest
      User {
        names
        lastname
        City {
          name
        }
        Country {
          name
        }
      }
    }
  }
`;

// deprecated
const getPreaffiliateByID = gql`
  query getPreaffiliateByID($userID: ID!) {
    PreaffiliateByID: getPreaffiliateByID(userID: $userID) {
      userID
      currentPhaseID
      CurrentPhase {
        subclass
        class
        extra {
          keyPhase
          keyNumber
        }
      }
      statusID
      Status {
        subclass
        class
      }
      phase1 {
        information {
          nationalityID
          Nationality {
            country
            demonym
          }
          documentNumber
          documentTypeID
          DocumentType {
            subclass
            class
          }
          frontDocumentFile
          backDocumentFile
          documentExpirationDate
          names
          lastname1
          lastname2
          genderID
          Gender {
            subclass
            class
          }
          birthdate
          academicFormationID
          AcademicFormation {
            subclass
            class
          }
          phone {
            phoneNumber
            phoneOperatorID
            PhoneOperator {
              subclass
              class
            }
            isMain
          }
          address
          hasExperience
          marketingSourceID
          MarketingSource {
            subclass
            class
          }
          company
          currentJob
          otherAppIDs
          otherOtherApp
          OtherApps {
            subtype
            subclass
          }
          availableDayIDs
          availableHourIDs
          AvailableHours {
            subtype
            subclass
          }
        }
        statusID
        Status {
          subclass
          class
        }
      }
      phase2 {
        information {
          vehiclePlate
          vehicleID
          Vehicle {
            name
            modelID
            brandID
            year
            categoryID
          }
          otherVehicle {
            vehicleCategory
            vehicleModel
            vehicleYear
            vehicleBrand
          }
          frontVehiclePropertyCardFile
          backVehiclePropertyCardFile
          vehicleRack
          vehicleTail
          driverLicenseNumber
          driverCategoryID
          DriverCategory {
            subtype
            subclass
          }
          frontDriverLicenseFile
          backDriverLicenseFile
          driverLicenseFileExpirationDate
          insuranceCompanyID
          InsuranceCompany {
            subtype
            subclass
          }
          insurancePolicyNumber
          policyFile
          policyFileExpirationDate
          ruc
          rucEnabledPaymentMethodIDs
          RucEnabledPaymentMethods {
            subtype
            subclass
          }
          rucFile
          bankID
          Bank {
            subtype
            subclass
          }
          bankAccountNumber
          interbankAccountNumber
          bankAccountFile
        }
        statusID
        Status {
          subclass
          class
        }
      }
      phase3 {
        information {
          phase3ExpirationDate
          recordVouchersFile
          hasCriminalRecords
          criminalRecordsFile
          criminalRecordsExpirationDate
        }
        statusID
        Status {
          subclass
          class
        }
      }
      phase4 {
        information {
          talkScheduleID
          evaluationApproved
          evaluationObservation
        }
        statusID
        Status {
          subclass
          class
        }
      }
      phase5 {
        information {
          phase5ExpirationDate
          hasImplements
          implementIDs
          implementsVoucherFile
          Implements {
            id
            categoryID
            Category {
              subtype
              subclass
              class
              extra {
                purchasable
              }
            }
            price
            serialNumber
            available
          }
        }
        statusID
        Status {
          subtype
          subclass
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export { getPreaffiliateByID, getAffiliateByID };
