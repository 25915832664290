import styled from "styled-components";
import { Modal } from "antd";

const GlobalContainer = styled.div`
  position: relative;
  background-color: #e5e5e5;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  .header-body {
    display: flex;
    position: relative;
    width: calc(100% - 264px);

    display: flex;
    flex-direction: column;
  }
  @media only screen and (max-width: 600px) {
    display: flex;
    background-color: white;
    flex-direction: column;
    .header-body {
      width: 100%;
    }
  }
`;

const ModalContainer = styled(Modal)`
  .subcontainer {
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  img {
    height: 136px;
    width: 136px;
    margin-bottom: 16px;
  }
  p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #8c8c8c;
    text-align: center;
  }

  h3 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #595959;
    margin-bottom: 16px;
    text-align: center;
  }

  .buttonModal {
    background-color: #307fe2;
    position: absolute;
    bottom: 40px;
    text-align: center;
  }
  .custom-upload {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 140px;
    height: 140px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 140px;
    height: 140px;
  }
  .ant-upload-list-picture-card-container {
    width: 140px;
    height: 140px;
  }
`;

const SignatureContainer = styled.div`
  border: 1px solid #d9d9d9;
  margin: 10px 20px 10px 20px;
  border-radius: 5px;
  position: relative;
  .bottom-clear {
    position: absolute;
    bottom: 5px;
    right: 5px;
    cursor: pointer;
  }
`;

export { GlobalContainer, ModalContainer, SignatureContainer };
