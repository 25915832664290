import React, { Component } from "react";
import { Layout } from "antd";
import styled from "styled-components";
import { Location } from "@reach/router";
import { GlobalContext } from "../../component-global";

const GeneralLayout = styled(Layout)`
  width: 100vw;
`;

const MainLayout = styled(Layout)`
  min-height: 100vh;
  background: #e5e5e5;
`;

export default class DashboardLayout extends Component {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
  };

  render() {
    const { collapsed } = this.state;
    const { children, authUser } = this.props;

    return (
      <Location>
        {() => (
          <GlobalContext authUser={authUser}>
            <GeneralLayout>
              <MainLayout collapsed={collapsed.toString()}>
                {children}
              </MainLayout>
            </GeneralLayout>
          </GlobalContext>
        )}
      </Location>
    );
  }
}
