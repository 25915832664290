import { ApolloClient, ApolloLink, InMemoryCache } from "apollo-boost";
import { withClientState } from "apollo-link-state";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import { createPersistedQueryLink } from "apollo-link-persisted-queries";
import fetch from "isomorphic-unfetch";
import { constants } from "../config";

let apolloClient = null;

if (!process.browser) {
  global.fetch = fetch;
}

function create() {
  const cacheMemory = new InMemoryCache({ addTypename: false });

  const errorHandler = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location:${JSON.stringify(
            locations
          )}, Path: ${path}`
        )
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const data = JSON.parse(
      sessionStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_PROVIDER}:${process.env.REACT_APP_AUTH_CLIENT_ID}`
      )
    );
    // get the authentication token from local storage if it exists
    const token =
      data &&
      data.access_token &&
      data.token_type &&
      `${data.token_type} ${data.access_token}`;
    /* get(
      JSON.parse(localStorage.getItem('authUser')),
      'ID'
    ) */
    // debugger
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: token ? `${token}` : "",
      },
    };
  });

  const httpLink = () =>
    createPersistedQueryLink().concat(
      createHttpLink({
        uri: constants.urlBack,
      })
    );

  const stateLink = withClientState({
    cache: cacheMemory,
    resolvers: {
      Mutation: {
        updateNetworkStatus: (_, { isConnected }, { cache }) => {
          const data = {
            networkStatus: {
              __typename: "NetworkStatus",
              isConnected,
            },
          };
          cache.writeData({ data });
          return null;
        },
      },
    },
  });

  const link = ApolloLink.from([errorHandler, authLink, stateLink, httpLink()]);

  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser, // Disables forceFetch on the server (so queries are only run once)
    link,
    cache: cacheMemory,
  });
}

export default function initApollo(initialState) {
  if (!process.browser) {
    return create(initialState);
  }

  if (!apolloClient) {
    apolloClient = create(initialState);
  }

  return apolloClient;
}
