import React from "react";
import { Input, Form } from "antd";

class InputComponent extends React.Component {
  render() {
    const {
      getFieldDecorator,
      decodator,
      placeholder,
      value,
      required,
      disabled,
      typeInput,
      autoCompleteInput,
    } = this.props;

    // console.log("daySelected", daySelected);

    return (
      <div>
        <Form.Item
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
        >
          {getFieldDecorator(decodator, {
            initialValue: value || undefined,
            rules: [
              {
                required,
                message: this.props.message,
              },
            ],
          })(
            <Input
              size="large"
              disabled={disabled}
              placeholder={placeholder}
              type={typeInput}
              autoComplete={autoCompleteInput}
            />
          )}
        </Form.Item>
      </div>
    );
  }
}

export default InputComponent;
