import React from "react";
import { Input } from "antd";
import { CustomFormItemInputWithBorder } from "../Form";

const { TextArea } = Input;

class TextAreaComponent extends React.Component {
  render() {
    const {
      getFieldDecorator,
      title,
      decodator,
      value,
      required,
      disabled,
      placeholder,
      numRows,
    } = this.props;

    // console.log("daySelected", daySelected);

    return (
      <div>
        <CustomFormItemInputWithBorder
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          label={title}
        >
          {getFieldDecorator(decodator, {
            initialValue: value || undefined,
            rules: [
              {
                required,
                message: ".",
              },
            ],
          })(
            <TextArea
              placeholder={placeholder}
              disabled={disabled}
              rows={numRows || 4}
            />
          )}
        </CustomFormItemInputWithBorder>
      </div>
    );
  }
}

export default TextAreaComponent;
