import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { Button, Icon } from "antd";
import { HeaderContainer, ResponsiveHeader } from "./styles";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { changeScroll } = this.props;
    return (
      <div>
        <HeaderContainer
          style={{ backgroundColor: changeScroll ? "white" : "transparent" }}
        >
          <div style={{ display: "flex" }}>
            <img
              style={{ marginRight: 65 }}
              height={32}
              alt="Chazi Logo"
              src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FlogoAzul.png?alt=media&token=3e30a697-89bf-413c-b7c3-5c3e55478833"
            />
            <div>
              <Button type="link" id="blueFont">
                Chazkis
              </Button>
              <Button type="link"> Servicio</Button>
              <Button type="link"> Partners</Button>
            </div>
          </div>
          <div>
            <Button
              type="link"
              className={changeScroll ? "blueFont" : "whiteFont"}
              onClick={this.props.login}
            >
              Iniciar sesión
            </Button>
            <Button
              onClick={this.props.register}
              type="primary"
              className={changeScroll ? "blueFont" : "whiteFont-button"}
            >
              Registrarse
            </Button>
          </div>
        </HeaderContainer>
        <ResponsiveHeader
          style={{ backgroundColor: changeScroll ? "white" : "transparent" }}
        >
          <img
            alt="chazi icon"
            src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2FchazkiIcon.png?alt=media&token=e6a5888e-ff41-4aad-90a9-61d09b862909"
          />
          <div>
            <Icon
              onClick={this.props.onClickheader}
              type="menu"
              style={{
                fontSize: 27,
                color: changeScroll ? "#307FE2" : "white",
              }}
            />
          </div>
        </ResponsiveHeader>
      </div>
    );
  }
}

export default compose(withApollo)(Header);

/*
  <button
    type="submit"
    className="login login-submit btn-submit"
    onClick={this.login}
  >
    Ingresar
  </button>

  <button
    style={{ margin: "4rem auto 2rem" }}
    type="submit"
    className="login login-submit btn-submit"
    onClick={() => navigate(`/${route.createAccount}`)}
  >
    Crear cuenta
  </button>

*/
