import styled from "styled-components";

const SidebarContainer = styled.div`
  width: 264px;
  height: 100vh;
  background-color: white;

  .LogoChazki {
    height: 80px;
    background: #307fe2;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 24px;
    }
    .closeButton {
      display: none;
    }
  }

  .progressContainer {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      margin-top: 12px;
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
    }
  }

  .cardStep {
    width: 264px;
    height: 52px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;

    .grayCircle {
      width: 24px;
      height: 24px;
      border-color: #bfbfbf;
      border-style: solid;
      border-width: 2px;
      border-radius: 24px;
    }

    span {
      margin-left: 8px;
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }

  @media only screen and (max-width: 600px) {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;

    width: ${props => (props.openSidebar ? "264px" : "0%")};

    .LogoChazki {
      .closeButton {
        position: absolute;
        left: 24px;
        display: block;
        color: white;
        font-size: 23px;
      }
    }
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  background-color: white;
  height: 80px;
  padding: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .IconMenuOpen {
    display: none;
    margin-right: 5px;
  }

  .namesUser {
    display: block;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #595959;
    margin-right: 10px;
  }

  .cityCountryNames {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #595959;
  }

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .IconMenuOpen {
      display: block;
      font-size: 30px;
      color: #307fe2;
    }
    .namesUser {
      display: none;
    }
    border-bottom: 1px solid #f0f0f0;
  }
`;

const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px 24px 24px 24px;

  && .WhiteContainer {
    background-color: white;
    width: 100%;
    height: auto;
    padding-top: 48px;
    padding-bottom: 48px;
    height: calc(100vh - 150px);
    overflow-y: auto;

    .titleStep {
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #595959;
    }
    .groupName {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #595959;
    }
    .groupDescription {
      font-family: Gilroy;
      font-style: normal;
      font-size: 16px;
      line-height: 20px;
      color: #595959;
    }
    .sendButton {
      margin-top: 22px;
    }
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 0px 0px 92px 0px;
    .WhiteContainer {
      padding: 24px 20px 0px 20px;
      .titleStep {
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
      }
      .groupName {
      }
      .sendButton {
      }
    }
  }
`;

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 50%; /*panorámico*/
  padding-top: 0px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-width: 1300px) {
    padding-bottom: 40%; /*panorámico*/
  }
`;

const VideoGallery = styled.div`
  display: flex;
  flex-direction: row;

  .videoScreen {
    width: calc(100% - 250px);
    height: auto;
    padding: 24px 0px 24px 0px;
    position: relative;
    iframe {
      height: 400px;
    }
  }

  .listVideos {
    width: 250px;
    height: auto;
    padding: 24px 0px 24px 24px;
    border-left: 1px solid #f0f0f0;
    .itemVideo {
      cursor: pointer;
      height: 68px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 24px 16px 24px 16px;
      border-bottom: 1px solid #f0f0f0;

      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #bfbfbf;
    }
    .itemVideo:hover {
      background-color: #f6f8fb;
      color: #bfbfbf;
    }
    #itemVideoSelected {
      background-color: #f6f8fb;
      color: #307fe2;
    }
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    .videoScreen {
      width: 100%;
      height: auto;
      iframe {
        width: 100%;
        height: 168px;
      }
    }
    .listVideos {
      width: 100%;
      padding: 0px;
      border-left: 0px solid white;
    }
  }
`;

export {
  BodyContainer,
  HeaderContainer,
  SidebarContainer,
  VideoContainer,
  VideoGallery,
};
