import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { Row, Col, Collapse } from "antd";
import { FooterBlue } from "./styles";

const { Panel } = Collapse;

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <FooterBlue>
        <div className="expandContainer">
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              style={{
                height: 300,
              }}
            >
              <h3>Sobre Chazi</h3>
              <div className="columnContainer">
                <a href="/">Servicios</a>
                <a href="/">Partners</a>
                <a href="/">Afiliados Chazki</a>
                <a href="/">La empresa</a>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              style={{
                height: 300,
              }}
            >
              <h3>Estamos en</h3>
              <div className="rowContainer">
                <img
                  width={28}
                  height={28}
                  alt="Peru"
                  src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fperu.png?alt=media&token=2262bd8f-fc3a-4353-ba80-be98423008b8"
                />
                <div>
                  <h5 className="titleCountry">Lima, Arequipa...</h5>
                  <span className="email">contacto.pe@chazki.com</span>
                </div>
              </div>
              <div className="rowContainer">
                <img
                  width={28}
                  height={28}
                  alt="Chile"
                  src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fchile.png?alt=media&token=e3ffd6d7-01b8-40f9-9911-68ff711279a9"
                />
                <div>
                  <h5 className="titleCountry">Santiago, Viña del Mar.</h5>
                  <span className="email">contacto.cl@chazki.com</span>
                </div>
              </div>
              <div className="rowContainer">
                <img
                  width={28}
                  height={28}
                  alt="Mexico"
                  src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fmexico.png?alt=media&token=80b5787d-9c2a-4ad6-95d4-3ed0d1379eff"
                />
                <div>
                  <h5 className="titleCountry">
                    Ciudad de México, Guadalajara, Monterrey, Querétaro, Toluca.
                  </h5>
                  <span className="email">contacto.mx@chazki.com</span>
                </div>
              </div>
              <div className="rowContainer">
                <img
                  width={28}
                  height={28}
                  alt="Argentina"
                  src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fargentina.png?alt=media&token=0461da8e-58f3-4d54-b6f6-d30d9f72ad09"
                />
                <div>
                  <h5 className="titleCountry">Buenos Aires</h5>
                  <span className="email">contacto.ar@chazki.com</span>
                </div>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              style={{
                height: 300,
              }}
            >
              <h3>Recursos</h3>
              <div className="columnContainer">
                <a href="/">Soporte</a>
                <a href="/">Libro de reclamaciones</a>
                <a href="/">Intranet</a>
                <a href="/">Políticas de privacidad</a>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              style={{
                height: 300,
              }}
            >
              <h3>Somos miembros de</h3>
              <div className="columnContainer">
                <img
                  height={36}
                  width={199}
                  alt="cace Chazki"
                  src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fcace.png?alt=media&token=96c9292a-0a10-4d25-83df-c6a824ca5a27"
                />
                <img
                  height={36}
                  width={199}
                  alt="enveavor"
                  src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fendeavor.png?alt=media&token=16e3b4ce-8daa-464c-8fd6-851ff4503d05"
                />
                <img
                  height={36}
                  width={199}
                  alt="startupPeru"
                  src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fstartupperu.png?alt=media&token=fd408ced-0711-441c-a07e-8396fee87bbd"
                />
              </div>
              <h3 style={{ marginTop: 20 }}>Descargar el App</h3>
              <img
                width={120}
                height={36}
                alt="Google Play Chazi"
                src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2FgooglePlay.png?alt=media&token=6476a73b-af22-4001-b06a-978078f81f80"
              />
            </Col>
          </Row>
          <div className="bottomFooter">
            <div className="SocialContainer">
              <img
                alt="instagram Chazki"
                src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Finstagram.png?alt=media&token=4edce2d7-6c66-4b35-ac91-dca204b927b0"
              />
              <img
                alt="Facebook Chazi"
                src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Ffacebook.png?alt=media&token=5a56e963-2d50-477d-8a32-d7c6a23078d5"
              />
              <img
                alt="LinkedIn Chazki"
                src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Flinkedin.png?alt=media&token=195e515d-5c58-4ed6-a048-f6aff1afa064"
              />
            </div>
            <div className="copyRight">
              Copyright 2020 Chazki - Todos los derechos reservados
            </div>
          </div>
        </div>
        <div className="collapseContainer">
          <Collapse expandIconPosition="right" bordered={false}>
            <Panel header="Sobre Chazki" key="1">
              <div className="columnContainer">
                <a href="/">Servicios</a>
                <a href="/">Partners</a>
                <a href="/">Afiliados Chazki</a>
                <a href="/">La empresa</a>
              </div>
            </Panel>
            <Panel header="Estamos en" key="2">
              <div className="rowContainer">
                <img
                  width={28}
                  height={28}
                  alt="Peru"
                  src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fperu.png?alt=media&token=2262bd8f-fc3a-4353-ba80-be98423008b8"
                />
                <div>
                  <h5 className="titleCountry">Lima, Arequipa...</h5>
                  <span className="email">contacto.pe@chazki.com</span>
                </div>
              </div>
              <div className="rowContainer">
                <img
                  width={28}
                  height={28}
                  alt="Chile"
                  src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fchile.png?alt=media&token=e3ffd6d7-01b8-40f9-9911-68ff711279a9"
                />
                <div>
                  <h5 className="titleCountry">Santiago, Viña del Mar.</h5>
                  <span className="email">contacto.cl@chazki.com</span>
                </div>
              </div>
              <div className="rowContainer">
                <img
                  width={28}
                  height={28}
                  alt="Mexico"
                  src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fmexico.png?alt=media&token=80b5787d-9c2a-4ad6-95d4-3ed0d1379eff"
                />
                <div>
                  <h5 className="titleCountry">
                    Ciudad de México, Guadalajara, Monterrey, Querétaro, Toluca.
                  </h5>
                  <span className="email">contacto.mx@chazki.com</span>
                </div>
              </div>
              <div className="rowContainer">
                <img
                  width={28}
                  height={28}
                  alt="Argentina"
                  src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fargentina.png?alt=media&token=0461da8e-58f3-4d54-b6f6-d30d9f72ad09"
                />
                <div>
                  <h5 className="titleCountry">Buenos Aires</h5>
                  <span className="email">contacto.ar@chazki.com</span>
                </div>
              </div>
            </Panel>
            <Panel header="Recursos" key="3">
              <div className="columnContainer">
                <a href="/">Soporte</a>
                <a href="/">Libro de reclamaciones</a>
                <a href="/">Intranet</a>
                <a href="/">Políticas de privacidad</a>
              </div>
            </Panel>
          </Collapse>
          <h3>Descarga la App</h3>

          <img
            alt="Google Play Chazi"
            src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2FgooglePlay.png?alt=media&token=6476a73b-af22-4001-b06a-978078f81f80"
          />
          <div
            style={{ height: "1px", marginTop: 20, backgroundColor: "#40A9FF" }}
          />
          <div className="SocialContainer">
            <img
              alt="instagram Chazki"
              src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Finstagram.png?alt=media&token=4edce2d7-6c66-4b35-ac91-dca204b927b0"
            />
            <img
              alt="Facebook Chazi"
              src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Ffacebook.png?alt=media&token=5a56e963-2d50-477d-8a32-d7c6a23078d5"
            />
            <img
              alt="LinkedIn Chazki"
              src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Flinkedin.png?alt=media&token=195e515d-5c58-4ed6-a048-f6aff1afa064"
            />
          </div>
          <p>Copyright 2020 Chazki - Todos los derechos reservados</p>
        </div>
      </FooterBlue>
    );
  }
}

export default compose(withApollo)(Footer);
