import React, { Component } from "react";
import { message, Spin } from "antd";
import { navigate } from "@reach/router";
import DashboardLayout from "./DashboardLayout";

import { AuthService } from "../../services/AuthService.ts";

const authService = new AuthService();
class DashboardWrapper extends Component {
  state = {
    user: null,
  };

  componentDidMount() {
    this.getUser();
  }

  getUser = async () => {
    localStorage.setItem("redirectUrl", this.props.location.href);
    const userData = await authService.getUser().then(
      user => {
        return user;
      },
      () => {
        navigate("/");
      }
    );
    if (!userData) {
      authService.renewToken().then(
        user => {
          if (user) {
            message.success("Usuario autenticado.");
            this.setState({ user });
          } else {
            navigate("/");
          }
        },
        () => {
          navigate("/");
        }
      );
    } else {
      message.success("Usuario autenticado.");
      this.setState({ user: userData });
    }
  };

  render() {
    const { children } = this.props;
    const { user } = this.state;
    return (
      <div>
        {user ? (
          <DashboardLayout authUser={user}>{children}</DashboardLayout>
        ) : (
          <div style={{ width: "100%" }}>
            <Spin tip="Cargando... " />
          </div>
        )}
      </div>
    );
  }
}

export default DashboardWrapper;
