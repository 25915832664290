import React from "react";
import { Form, Modal, message, Spin, Button, notification } from "antd";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { getFormByStep } from "../../graphQl/queries/formSteps";
import { withGlobal } from "../../component-global";
import Header from "./Components/Header";
import Sidebar from "./Components/SideBar";
import Body from "./Components/Body";
import { GlobalContainer, ModalContainer } from "./styles";
import { saveDataForms } from "../../graphQl/mutations/formSteps";
import { captureException } from "../../utils/sentry";
import { getAffiliateByID } from "../../graphQl/queries/preaffiliate";

// import * as route from "../../../routes/routes";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataForm: [],
      currentStepID: null,
      loadingOnSave: false,
      disabledInputs: false,
      loadingRequest: false,
      openSidebar: false,
      userData: null,
    };
  }

  componentDidMount = async () => {
    this.requestData();
  };

  requestData = async () => {
    try {
      this.setState({ loadingRequest: true });

      let variables = {
        userID: this.props.authUser.profile.sub,
      };
      const {
        data: { AffiliateByID },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getAffiliateByID,
        variables,
      });

      if (AffiliateByID.stepID) {
        this.setState({
          currentStepID: AffiliateByID.stepID,
          userData: AffiliateByID.User,
          completeTest: AffiliateByID.completeTest,
        });

        variables = {
          affiliateID: this.props.authUser.profile.sub,
          stepID: AffiliateByID.stepID,
        };
        const {
          data: { FormByStep },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getFormByStep,
          variables,
        });

        this.setState({
          loadingRequest: false,
          dataForm: FormByStep.concat({ name: "Descarga la aplicación" }),
        });
      } else {
        notification.error({
          message: "Hubo un error con el usuario",
          description: "No se encontró el StepID del usuario actual",
        });
        this.setState({ loadingRequest: false });
      }
    } catch (err) {
      console.log(err);
      this.setState({ loadingRequest: false });
    }
  };

  setCustomModalMessage = (title, description, image) => {
    this.setState({
      CustomModalTitle: title,
      CustomModalDescription: description,
      CustomModalImage: image,
    });
  };

  confirm = () => {
    Modal.confirm({
      title: "¿Esta seguro que desea cerrar sesion?",
      okText: "Si",
      cancelText: "No",
      onOk: this.logout,
    });
  };

  onSaveChanges = values => {
    this.setState({ loadingOnSave: true });
    this.props.client
      .mutate({
        mutation: saveDataForms,
        update: (cache, { data: { SaveDataForms } }) => {
          notification.success({ message: "El registro se realizó con éxito." });
          this.setState({ loadingOnSave: false });
          this.requestData();
          this.setState({
            visibleModal: true,
            ModalTitle: SaveDataForms.message,
            ModalDescription: SaveDataForms.message,
          });
        },
        variables: {
          currentStep: this.state.currentStepID,
          data: values,
        },
      })
      .catch(error => {
        captureException(error);
        if (error.graphQLErrors) {
          message.error(error.graphQLErrors.map(err => err.message).join(", "));
        } else {
          message.error("No se pudo guardar, error en el sistema");
        }
        this.setState({ loadingOnSave: false });
      });
  };

  onSidebarMenu = () => {
    const { openSidebar } = this.state;
    this.setState({ openSidebar: !openSidebar });
  };

  render() {
    const { dataForm } = this.state;

    return (
      <GlobalContainer>
        <Sidebar
          loadingRequest={this.state.loadingRequest}
          dataForm={dataForm}
          stepID={this.state.currentStepID}
          onSidebarMenu={this.onSidebarMenu}
          openSidebar={this.state.openSidebar}
          completeTest={this.state.completeTest}
        />
        <div className="header-body">
          <Header
            onSidebarMenu={this.onSidebarMenu}
            userData={this.state.userData}
          />
          <Spin tip="Cargando" spinning={this.state.loadingRequest}>
            <Body
              currentStepID={this.state.currentStepID}
              dataForm={dataForm.find(
                obj =>
                  parseInt(obj.id, 10) ===
                  parseInt(this.state.currentStepID, 10)
              )}
              loadingRequest={this.state.loadingRequest}
              onSaveChanges={this.onSaveChanges}
              loadingOnSave={this.state.loadingOnSave}
              disabledInputs={this.state.disabledInputs}
              completeTest={this.state.completeTest}
              setCustomModalMessage={this.setCustomModalMessage}
              userData={this.state.userData}
            />
          </Spin>
        </div>
        <ModalContainer
          visible={this.state.visibleModal}
          width={420}
          bodyStyle={{ height: 456 }}
          footer={null}
          closable={false}
        >
          <div className="subcontainer">
            <img
              alt="Icon Chazki"
              src={
                this.state.CustomModalImage ||
                "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2FGroup%201099.png?alt=media&token=c85c837e-18ea-46ca-b67f-b403dffa3440"
              }
            />
            <h3>{this.state.CustomModalTitle || this.state.ModalTitle}</h3>
            <p>
              {this.state.CustomModalDescription || this.state.ModalDescription}
            </p>
            <Button
              className="buttonModal"
              size="large"
              type="primary"
              onClick={() => this.setState({ visibleModal: false })}
            >
              Entendido
            </Button>
          </div>
        </ModalContainer>
      </GlobalContainer>
    );
  }
}

const WraperRegister = withGlobal(Register);
const SaveRegister = Form.create()(WraperRegister);

export default compose(withApollo)(SaveRegister);
