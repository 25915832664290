import gql from "graphql-tag";

const saveDataForms = gql`
  mutation saveDataForms($currentStep: Int, $data: [InputDataForm]) {
    SaveDataForms: saveDataForms(currentStep: $currentStep, data: $data) {
      message
      motive
      statusCode
    }
  }
`;

const nextStepByAffiliate = gql`
  mutation nextStepByAffiliate($affiliateID: String) {
    NextStepByAffiliate: nextStepByAffiliate(affiliateID: $affiliateID) {
      message
      motive
      statusCode
    }
  }
`;

export { saveDataForms, nextStepByAffiliate };
