import React from "react";
import { Checkbox } from "antd";
import { FormItemInvisible } from "../Form";

class CheckBoxComponent extends React.Component {
  render() {
    const {
      getFieldDecorator,
      decodator,
      title,
      value,
      textDescripton,
      required,
    } = this.props;
    return (
      <div style={{ marginBottom: 20 }}>
        <FormItemInvisible
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          label={title}
        >
          {getFieldDecorator(decodator, {
            initialValue: value || undefined,
            rules: [
              {
                required,
                message: "Por favor, ingrese la razón social",
              },
            ],
          })(<Checkbox>{textDescripton}</Checkbox>)}
        </FormItemInvisible>
      </div>
    );
  }
}

export default CheckBoxComponent;
