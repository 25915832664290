import { Log, User, UserManager } from 'oidc-client';
import { constantsOauth } from '../config';

export class AuthService {
  public userManager: UserManager;

  constructor() {
    const settings = {
      authority: constantsOauth.stsAuthority,
      client_id: constantsOauth.clientId,
      //client_secret: "bar",
      redirect_uri: `${constantsOauth.clientRoot}signin-callback.html`,
      silent_redirect_uri: `${constantsOauth.clientRoot}silent-renew.html`,
      // tslint:disable-next-line:object-literal-sort-keys
      post_logout_redirect_uri: `${constantsOauth.clientRoot}`,
      response_type: 'code',
      scope: constantsOauth.clientScope
    };
    this.userManager = new UserManager(settings);

    Log.logger = console;
    Log.level = Log.INFO;
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent();
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }
}
