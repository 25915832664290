import React from "react";
import { Form, Modal, Icon, Avatar, Dropdown, Button, Menu } from "antd";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { withGlobal } from "../../../component-global";
import { AuthService } from "../../../services/AuthService.ts";
import { HeaderContainer } from "./styles";
// import * as route from "../../../routes/routes";

const authService = new AuthService();
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  logout = () => {
    authService.logout();
  };

  confirm = () => {
    Modal.confirm({
      title: "¿Esta seguro que desea cerrar sesion?",
      okText: "Si",
      cancelText: "No",
      onOk: this.logout,
    });
  };

  render() {
    const menu = (
      <Menu>
        <Menu.Item onClick={this.logout}>Cerrar Sesión</Menu.Item>
      </Menu>
    );

    return (
      <HeaderContainer>
        <div className="IconMenuOpen">
          <Icon type="menu" onClick={this.props.onSidebarMenu} />
        </div>
        <div style={{ textAlign: "center" }}>
          Postulando a
          <span className="cityCountryNames">
            {" "}
            {this.props.userData && this.props.userData.City
              ? this.props.userData.City.name
              : null}
            {" | "}
            {this.props.userData && this.props.userData.Country
              ? this.props.userData.Country.name
              : null}
          </span>
        </div>
        <div>
          <Dropdown overlay={menu} placement="bottomCenter">
            <Button type="link">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar
                  src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                  size={36}
                />
                <div className="namesUser">
                  {this.props.userData
                    ? `${this.props.userData.names} ${this.props.userData.lastname}`
                    : null}{" "}
                  <Icon
                    type="down"
                    style={{
                      color: "#307FE2",
                      marginLeft: "10px",
                      fontSize: 15,
                    }}
                  />
                </div>
              </div>
            </Button>
          </Dropdown>
        </div>
      </HeaderContainer>
    );
  }
}

const WraperHeader = withGlobal(Header);
const HeaderForm = Form.create()(WraperHeader);

export default compose(withApollo)(HeaderForm);
