import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { Row, Col, Carousel, Collapse } from "antd";
import { VerticalInformativeBlock } from "../../../components/Layout/LadingComponents/VerticalInformativeBlock";
import {
  BodyLanding,
  ServicesBlock,
  RegisterBlock,
  FaqBlock,
  OpinionsBlock,
  AppBlock,
} from "./styles";

const { Panel } = Collapse;

const testimonials = [
  {
    //name: "Diego Alonso",
    //testimonial:
    name:
      "“En Chazki te ayudan en todo momento para solucionar inconvenientes que surgen durante la ruta”",
    url:
      "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/gameboy_files%2Fresources%2Ftestimonials%2Fuser3.png?alt=media&token=46faea35-5316-4334-843d-41fc1e593c42",
  },
  {
    name:
      "“En Chazki siempre hay un constante flujo de pedidos, el tiempo de respuesta es rápido y no pierdes tiempo”",
    url:
      "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/gameboy_files%2Fresources%2Ftestimonials%2Fuser2.png?alt=media&token=7b79cd1c-bce0-4e5b-a03b-e5a7b62ff893",
  },
  {
    name:
      "“Chazki representa mi principal fuente de ingreso económico para mi hogar”.",
    url:
      "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/gameboy_files%2Fresources%2Ftestimonials%2Fuser1.png?alt=media&token=5819c110-ae23-44d1-9e15-792580245657",
  },
];

class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const displayProfilesPhoto = () =>
      testimonials.map(obj => {
        return (
          <div className="photoProfile" key={obj.name}>
            <img alt="user1" height="348px" src={obj.url} />
          </div>
        );
      });

    const displayTestimonials = () =>
      testimonials.map(obj => {
        return (
          <div style={{ backgroundColor: "red", width: 100 }} key={obj.name}>
            <div className="containerOpinion">
              <div className="imageContainer">
                <img alt="user1" height="348px" src={obj.url} />
              </div>
              <div className="containerDescription">
                <p>{obj.testimonial}</p>
                <h4>{obj.name}</h4>
                <div className="photoProfileContainer">
                  {displayProfilesPhoto()}
                </div>
              </div>
            </div>
          </div>
        );
      });

    const landingObj = (this.props.countriesData || []).find(obj => {
      if (
        this.props.currentCountryInfo &&
        this.props.currentCountryInfo.country_name
      )
        return (
          obj.name.toUpperCase() ===
          this.props.currentCountryInfo.country_name.toUpperCase()
        );
      return false;
    });

    const requirements =
      landingObj && landingObj.landing ? landingObj.landing.requirements : [];
    const documents =
      landingObj && landingObj.landing ? landingObj.landing.documents : [];

    const displayDocuments = () =>
      documents.map(obj => {
        return (
          <div className="divRow" key={obj.description}>
            <img alt={obj.description} src={obj.urlImage} />
            <p>{obj.description}</p>
          </div>
        );
      });

    const displayRequirements = () =>
      requirements.map(obj => {
        return (
          <div className="divRow" key={obj.description}>
            <img alt={obj.description} src={obj.urlImage} />
            <p>{obj.description}</p>
          </div>
        );
      });

    return (
      <BodyLanding>
        <h3 className="centerText" name="secondInsideContainer">¿Por qué ser un Chazki?</h3>
        <p className="centerText">
          Somos la Startup con mayor crecimiento a nivel Latam, conoce algunas
          ventajas
        </p>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={8}
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <VerticalInformativeBlock
              image="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/gameboy_files%2Fresources%2Fguy.png?alt=media&token=8cd8adfa-6552-46ad-a6fc-46c6ef96a899"
              title="Tú eliges tus rutas"
              description="Con la nueva App podrás decidir que ruta tomar y si eres un capo de las pistas podrás optimizar una ruta a tu favor."
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={8}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <VerticalInformativeBlock
              title="Siempre asesorado"
              image="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/gameboy_files%2Fresources%2Fgirls.png?alt=media&token=0b7d2b8c-b0e7-48a5-83a6-869befbca3e9"
              description="Nuestro sistema y operadores te ayudarán a trackear tus recojos y entregas. En caso de tener una incidencia solo avísanos."
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={8}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <VerticalInformativeBlock
              title="Repartes valor"
              image="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/gameboy_files%2Fresources%2Fcompras.png?alt=media&token=2bc80238-5666-47fb-a1a2-93ba526d0510"
              description="Aquí no vas a recoger personas ni entregar comida, tu misión es repartir directamente para empresas grandes y pequeñas."
            />
          </Col>
        </Row>
        <ServicesBlock>
          <Row gutter={32}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="circleBlue">
                <img
                  alt="chazki"
                  width="552"
                  src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/gameboy_files%2Fresources%2FMask%20Group.png?alt=media&token=b6c8a387-0af4-45b2-bf26-00da9571cf05"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={2} xl={2} />
            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
              <h3>¿Qué servicios vas a realizar como Chazki?</h3>
              <div className="servicesContainer">
                <div className="divRow">
                  <img
                    alt="img1"
                    src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2Fairplane.png?alt=media&token=6b382e4c-8796-4370-a369-b5e0fde2d904"
                  />
                  <div className="divComlumn">
                    <h4>Express</h4>
                    <p>
                      Deberás asegurar que los envíos lleguen en un máximo de 3
                      horas
                    </p>
                  </div>
                </div>
                <div className="divRow">
                  <img
                    alt="img2"
                    src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2Fcalendar.png?alt=media&token=0bc723e7-7f16-4104-aba5-b6d647b23cdf"
                  />
                  <div className="divComlumn">
                    <h4>Next Day</h4>
                    <p>
                      Recoges el día anterior y entregas al día siguiente
                      durante el día.
                    </p>
                  </div>
                </div>
                <div className="divRow">
                  <img
                    alt="img3"
                    src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2Fclock.png?alt=media&token=4c717fc8-6e49-4e07-aecb-4261675961c2"
                  />
                  <div className="divComlumn">
                    <h4>Next Day Programado</h4>
                    <p>
                      Recoges el día anterior y entrega al día siguiente en
                      ventanas horarias
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </ServicesBlock>
        {documents.length && requirements.length ? (
          <RegisterBlock>
            <h3 className="centerText">¿Qué necesitas para registrarte?</h3>
            <p className="centerText">
              Antes de registrarte debes cumplir con lo siguiente
            </p>
            <Row gutter={32} className="columnsContainer">
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <h4>Requisitos</h4>
                {displayRequirements()}
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{}}>
                <h4>Documentos</h4>
                {displayDocuments()}
              </Col>
            </Row>
          </RegisterBlock>
        ) : null}
        <OpinionsBlock>
          <h3 className="centerText">Lo que opinan nuestros Chazkis</h3>
          <Carousel autoplay>{displayTestimonials()}</Carousel>
        </OpinionsBlock>
        <AppBlock>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              className="colcontainer"
            >
              <h3>
                La App Chazki Driver <br />
                ahora se renueva <br />
                gracias a tí y para tí
              </h3>
              <p>Descubre las novedades descárgalo en:</p>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="containerPhone">
                <div className="circleBlue" />
                <img
                  alt="phone"
                  src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2FphoneImg.png?alt=media&token=b80d8338-74ff-40f4-86a1-07e181cd20bf"
                />
              </div>
            </Col>
          </Row>
        </AppBlock>
        <FaqBlock>
          <h3>Respondemos algunas dudas</h3>
          <Collapse
            expandIconPosition="right"
            bordered={false}
            className="site-collapse-custom-collapse faqContainer"
          >
            <Panel
              header="This is panel header 1"
              key="1"
              className="site-collapse-custom-panel"
            >
              <p></p>
            </Panel>
            <Panel
              header="This is panel header 2"
              key="2"
              className="site-collapse-custom-panel"
            >
              <p></p>
            </Panel>
            <Panel
              header="This is panel header 3"
              key="3"
              className="site-collapse-custom-panel"
            >
              <p></p>
            </Panel>
          </Collapse>
        </FaqBlock>
      </BodyLanding>
    );
  }
}

export default compose(withApollo)(Body);

/*
  <button
    type="submit"
    className="login login-submit btn-submit"
    onClick={this.login}
  >
    Ingresar
  </button>

  <button
    style={{ margin: "4rem auto 2rem" }}
    type="submit"
    className="login login-submit btn-submit"
    onClick={() => navigate(`/${route.createAccount}`)}
  >
    Crear cuenta
  </button>

*/
