/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import moment from "moment";
import { Icon, Button, Radio, Form, Row, Col } from "antd";
import { withApollo } from "react-apollo";
import { compose } from "recompose";
import { withGlobal } from "../../../component-global";
import { VideoContainer, VideoGallery } from "./styles";

class CustomResource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceSelected: 0,
    };
  }

  extractVideoID = youTubeUrl => {
    let videoId = youTubeUrl.split("v=")[1];
    const ampersandPosition = videoId.indexOf("&");
    if (ampersandPosition !== -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }    
    return videoId;
  };

  onClickResource = idx => {
    this.setState({ resourceSelected: idx });
  };

  nextResource = () => {
    const { resourceSelected } = this.state;
    if (resourceSelected + 1 < this.props.resources.length)
      this.setState({ resourceSelected: resourceSelected + 1 });
    else {
      this.props.onSaveChanges([]);
    }
  };

  prevResource = () => {
    const { resourceSelected } = this.state;
    if (resourceSelected - 1 >= 0)
      this.setState({ resourceSelected: resourceSelected - 1 });    
  };

  displayInputType = (
    type,
    placeholder,
    disabled,
    options,
    required,
    getFieldDecorator,
    decodator,
    value,
    resources,
    name,
    decorator
  ) => {
    let response = null;
    switch (type) {
      case "video":
        response = (
          <VideoContainer>
            <iframe
              title="Chazki Video"
              width="838"
              height="300"
              src={`https://www.youtube.com/embed/${this.extractVideoID(
                value
              )}`} 
              allowFullScreen             
            />
          </VideoContainer>
        );
        break;
      case "groupVideo":
        console.log(resources);
        response = (
          <VideoGallery>
            <div className="videoScreen">
              <iframe
                title="Chazki Video"
                width="100%"
                height="470"
                src={`https://www.youtube.com/embed/${this.extractVideoID(
                  resources[this.state.resourceSelected].url
                )}`}
                allowFullScreen
              />
              <h3 style={{ marginTop: "10px" }}>
                {resources && resources.length > 0
                  ? resources[this.state.resourceSelected].title
                  : ""}
              </h3>
              <p style={{ marginTop: "10px" }}>
                {resources && resources.length > 0
                  ? resources[this.state.resourceSelected].description
                  : ""}
              </p>
              {this.state.resourceSelected - 1 >= 0?
              (<Button
                onClick={this.prevResource}
                style={{ borderColor: "#307FE2", color: "#307FE2", height: 48 ,marginRight:10}}
                loading={this.props.loadingOnSave}
              >
                <Icon type="left" />
                Retroceder                
              </Button>):[]}
              <Button
                onClick={this.nextResource}
                style={{ borderColor: "#307FE2", color: "#307FE2", height: 48 }}
                loading={this.props.loadingOnSave}
              >
                {this.state.resourceSelected + 1 >= this.props.resources.length
                  ? "Responder Test"
                  : "Continuar"}
                <Icon type="right" />
              </Button>
            </div>
            <div className="listVideos">
              <h3>Temas</h3>
              {(resources || []).map((obj, idx) => {
                return (
                  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                  <div
                    key={obj.title}
                    className="itemVideo"
                    onClick={() => this.onClickResource(idx)}
                    id={
                      idx === this.state.resourceSelected
                        ? "itemVideoSelected"
                        : null
                    }
                  >
                    <Icon type="play-circle" style={{ marginRight: "5px" }} />
                    {obj.title}
                  </div>
                );
              })}
            </div>
          </VideoGallery>
        );
        break;
      case "radio":
        response = (
          <Row>
            <h3>{name}</h3>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item>
                {getFieldDecorator(decorator, {
                  rules: [
                    {
                      required,
                      message: this.props.message,
                    },
                  ],
                })(
                  <Radio.Group disabled={disabled} required>
                    {(options || []).map(obj => {
                      return (
                        <Radio key={obj.subclass} value={obj.subclass}>
                          {obj.subclass}
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>
        );
        break;
      default:
        response = <p>{value}</p>;
        break;
    }
    return response;
  };

  valueFormated = (value, type) => {
    if (value) {
      if (type === "calendar") {
        console.log("value Calendar", moment(value));
        return moment(value);
      }
      if (type === "select") {
        return parseInt(value, 10);
      }
      return value;
    }
    return null;
  };

  render() {
    const {
      placeholder,
      getFieldDecorator,
      decodator,
      required,
      value,
      disabled,
      type,
      options,
      resources,
      name,
      decorator,
    } = this.props;

    return (
      <div
        style={{
          width: "100%",
          marginBottom: 0,
        }}
      >
        {this.displayInputType(
          type,
          placeholder,
          disabled,
          options,
          required,
          getFieldDecorator,
          decodator,
          value,
          resources,
          name,
          decorator
        )}
      </div>
    );
  }
}

const WraperCustomResource = withGlobal(CustomResource);
const CustomResourceForm = Form.create()(WraperCustomResource);

export default compose(withApollo)(CustomResourceForm);
