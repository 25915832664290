// import { init, captureMessage, captureException } from "@sentry/browser";
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://eb26e89f3a4f486abf2f63a7dff2783b@sentry.io/1869456",
});

const captureMessage = msj => {
  Sentry.captureMessage(msj);
};

const captureException = msj => {
  Sentry.captureException(new Error(msj));
};

export { captureMessage, captureException };
