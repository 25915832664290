import React from "react";
import { Router } from "@reach/router";
import { ApolloConsumer } from "react-apollo";
import { GlobalLayout } from "../components/Layout";
import * as route from "./routes";
import DashboardWrapper from "../components/Layout/DashboardWrapper";
import Landing from "../pages/Landing/Landing";
import CreateAccount from "../pages/CreateAcount/CreateAccount";
// import NotFound from "../pages/NotFound";
import Register from "../pages/Register/Register";
import CheckCode from "../pages/CreateAcount/CheckCode";

const RouterApp = () => (
  <GlobalLayout>
    <ApolloConsumer>
      {client => (
        <Router>
          <Landing path="/" />
          <CheckCode path="check-code/:code" />
          <CreateAccount path={route.createAccount} />
          <DashboardWrapper path={route.dashboard} client={client}>
            <Register path={`${route.register}/:userId`} />
          </DashboardWrapper>
        </Router>
      )}
    </ApolloConsumer>
  </GlobalLayout>
);

export default RouterApp;
