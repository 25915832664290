import { message } from "antd";
import { get } from "lodash";
import moment from "moment";
import axios from "axios";
import FileSaver from "file-saver";

const formatSnapShot = snapshot => {
  const returnArray = [];

  snapshot.forEach(childSnapshot => {
    const item = childSnapshot.data();
    item.id = childSnapshot.id;

    returnArray.push(item);
  });

  return returnArray;
};

const serializeObject = obj => {
  return Object.keys(obj)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join("&");
};

const sendNotification = async ({ userId, message, actionTo, userToken }) => {
  try {
    const notification = await axios.post(
      "https://fcm.googleapis.com/fcm/send",
      {
        notification: {
          title: "Chazki",
          body: message,
          click_action: actionTo,
          icon:
            "https://is4-ssl.mzstatic.com/image/thumb/Purple128/v4/55/41/12/55411208-1e13-19ad-6c44-1aff43bbf149/source/512x512bb.jpg",
        },
        to: userToken,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${process.env.REACT_APP_NOTIFICATION_TOKEN}`,
        },
      }
    );

    console.log("PUSH response", notification);
  } catch (e) {
    console.log("Cannot send the push notification", e);
  }
};

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = file => {
  const isJPG = file.type === "image/jpeg";
  if (!isJPG) {
    message.error("¡Sólo puedes subir archivos JPG!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("¡La imagen debe ser menor a 2 MB!");
  }
  return isJPG && isLt2M;
};

const uploadFile = (
  action, // NO
  data, // NO
  file, // YES
  filename, // NO
  onError, // YES
  onProgress, // YES
  onSuccess, // YES
  firebase,
  key
) => {
  const task = firebase.refStorage.child(key).put(file);

  task.on(
    "state_changed",
    function progress(snapshot) {
      const percentage =
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      onProgress({ percent: percentage });
    },
    err => {
      console.log("error on upload", err);
      return onError();
    },
    () => {
      task.snapshot.ref.getDownloadURL().then(downloadURL => {
        console.log("Upload has completed");
        onSuccess({ file: downloadURL });
      });
    }
  );
};

const onFileUpload = (info, onUpload, done) => {
  const {
    file: { status },
  } = info;

  if (status === "uploading") {
    onUpload();
  }

  if (status === "done") {
    done();
  }
};

const normFile = e => {
  console.log("ER", e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList.map(el => ({ ...el, url: get(el, "response.file") }));
};

const formatFileArray = arrayFile =>
  (arrayFile || []).map(i => ({
    name: i.name,
    uid: i.uid,
    status: "done",
    url: i.url ? i.url : get(i, "response.file"),
  }));

const disabledPast = current =>
  current &&
  current <
    moment()
      .subtract(1, "days")
      .endOf("day");

const disabledFuture = current =>
  current &&
  current >
    moment()
      .subtract(1, "days")
      .endOf("day");

const downloadString = (tableHeaders, dataArrays, fileName, delimeter) => {
  const strArray = [tableHeaders.join(delimeter)];

  dataArrays.forEach(dataArray => {
    const s = dataArray.join(delimeter);
    strArray.push(s);
  });

  const str = strArray.join("\n");

  const blob = new Blob([str], {
    type: "text/plain;charset=utf-8",
  });
  FileSaver.saveAs(blob, fileName);
};

const getBase64Promise = img => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const fileNameStamp = name => {
  const getDate = new Date();
  getDate.setHours(getDate.getHours() - 5);
  let timestamp = getDate.toISOString();
  timestamp = timestamp.replace(/:/g, "-");
  timestamp = timestamp.replace(/\./g, "-");
  return timestamp + name.replace(/ /g, "_");
};

export {
  formatSnapShot,
  serializeObject,
  sendNotification,
  getBase64,
  beforeUpload,
  formatFileArray,
  normFile,
  disabledFuture,
  disabledPast,
  onFileUpload,
  uploadFile,
  downloadString,
  getBase64Promise,
  fileNameStamp,
};
