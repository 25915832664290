import React from "react";
import { compose } from "recompose";
import { Form, Col, Row, message, Button, Input, Select, Checkbox, Typography, Upload, Icon, Modal } from "antd";
import { withApollo, graphql } from "react-apollo";
import styled from "styled-components";
import { navigate } from "@reach/router";
import UAParser from "ua-parser-js";
import publicIp from "public-ip";
import { renderOptions, renderOptionsWithImg } from "./static";
// import { PerfilContainer } from "./style";
// import * as route from "../../../routes/routes";
import { createPreaffiliateUser } from "../../graphQl/mutations/account";
import { captureException } from "../../utils/sentry";
import { getAllCountries } from "../../graphQl/queries/countries";
import {
  InputComponent,
  SelectComponent,
  InputEmailComponent,
} from "../../components/FormFields";
import { ModalContainer, SignatureContainer } from "../Register/styles";
import SignaturePad from "react-signature-pad-wrapper";
import { beforeUpload, fileNameStamp, getBase64Promise } from "../../utils/functions";
import { withFirebase } from "../../firebase";
//import './styles.css';
// const urlBackground =
//  "https://chazkistorage.blob.core.windows.net/resources/portada-chazki.jpg";
const {  Text, Paragraph } = Typography;
const BodyCreateUser = styled.div`
  position: relative;
  display: flex;
  height: 100vh;
  flex-direction: row;

  .RightSide {
    width: 60%;
    .GreenContainerCircle {
      position: absolute;
      top: -223px;
      left: -190px;
      width: 1030px;
      height: 1030px;
      background-color: #44d62c;
      border-radius: 650px;
      overflow: hidden;
    }

    img {
      height: 612px;
      position: absolute;
      bottom: 0px;
      left: 0px;
    }

    @media only screen and (max-width: 50em) {
      display: none;
    }
  }
  .LeftSide {
    display: flex;
    width: 40%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 36px;
      color: #595959;
      margin-bottom: 24px;
    }

    .formStyle {
      width: 100%;

      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }

    .ant-btn {
      width: 100%;
      height: 48px;
    }

    .login {
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;

      a {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: 307fe2;
      }
      margin-top: 17px;
    }

    .message-afther-create {
      padding: 20px;
      text-align: center;
    }

    .btn-create-account {
      background-color: #307fe2;
      color: white;
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
    }

    @media only screen and (max-width: 50em) {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;

      align-items: flex-start;
      justify-content: flex-start;
      padding-top: 30px;
      padding-bottom: 20px;

      .login {
        text-align: center;
      }
    }
  }
`;

class CreateAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sentDateToCreate: false,
      loadingBtnSumit: false,
      ip: null,
      viewModal: false,
      checked: false,
      loadingUpload: false,
      fileList: [],
    };
    this.signaturePad = React.createRef();
  }

  componentDidMount = async () => {
    this.getIpPublic();
  };

  getIpPublic = async () => {
    const myIp = await publicIp.v4();
    this.setState({ ip: myIp });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // console.log("values", values);
        if(!values.acceptTC) {
          message.error("Acepte los terminos i condiciones para continuar.");
          return
        }
        const formatValues = values;
        formatValues.cityID = Number(values.cityID);
        formatValues.urlRedirect = `${process.env.REACT_APP_URL}check-code`;
        formatValues.urlLogin = `${process.env.REACT_APP_URL}login`;
        formatValues.versionTC = this.props.getAllCountries.GetAllCountries.find(
          obj => obj.id === values.countryID
        ).versionTC;

        formatValues.deviceIP = this.state.ip;

        const deviceData = new UAParser();
        formatValues.deviceInfo = {
          mac: null,
          model: deviceData.getDevice().model,
          brand: deviceData.getEngine().name,
          versionSO: JSON.stringify(deviceData.getOS()),
        };

        delete formatValues.agreement;
        // console.log("formatValues", formatValues);
        this.uploadImages(formatValues);
      }
    });
  };

  FunCreateAccount = variables => {
    this.props.client
      .mutate({
        mutation: createPreaffiliateUser,
        update: (cache, { data: { CreatePreaffiliateUser } }) => {
          console.log("CreatePreaffiliateUser", CreatePreaffiliateUser);
          this.setState({ loadingBtnSumit: false, sentDateToCreate: true });
          setTimeout(() => {
            navigate(`/`);
          }, 15000);
        },
        variables,
      })
      .catch(err => {
        captureException(err);
        message.error("No se pudo guardar, error en el sistema");
        this.setState({ loadingBtnSumit: false });
      });
  };

  uploadImages = async (values) => {
    const { firebase } = this.props;
    try {
      this.setState({ loadingBtnSumit: true });

      const signatureImage = this.signaturePad.current.toDataURL('image/png');
      const selfieImage = await getBase64Promise(this.state.fileList[0].originFileObj);

      const uploadPromise = [signatureImage, selfieImage].map(base64Image => {
        const fileStamp = fileNameStamp(`${values.names}_${values.lastname}`);
        const uploadTask = firebase.refStorage.child(`gameboy_files/tyc_evidence/${fileStamp}`).putString(base64Image, 'data_url');
        return new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              console.log("uploading ... ", progress);
            },
            (error) => reject(error),
            () => {
              firebase.refStorage
                .child(`gameboy_files/tyc_evidence/${fileStamp}`)
                .getDownloadURL()
                .then((url) => resolve(url))
                .catch((error) => reject(error));
            }
          );
        });
      });
      const [urlFileSignature, urlFileSelfie] = await Promise.all(uploadPromise);

      this.FunCreateAccount({...values, urlFileSignature, urlFileSelfie});
    } catch (error) {
      message.error("Error al cargar las imágenes, vuelva a intentarlo.")
      this.setState({
        loadingBtnSumit: false,
      });
    }
  };

  onChangeCountries = countryID => {
    const { resetFields } = this.props.form;
    const {
      getAllCountries: { GetAllCountries },
    } = this.props;
    resetFields(["cityID"]);
    const positionCity =
      GetAllCountries && GetAllCountries.findIndex(x => x.id === countryID);
    if (positionCity > -1)
      this.setState({
        cities: GetAllCountries[positionCity].Cities,
        termsandconditions: GetAllCountries[positionCity].termsandconditions,
      });
  };

  handleTyC = e => {
    if(e.target.checked) {
      this.setState({viewModal: true});
    } else {
      this.setState({fileList : []})
      this.signaturePad.current.clear();
    }
  };

  handleChangeUpload = ({ file, fileList }) => this.setState({ fileList: file.type === 'image/jpeg' ? fileList : [] });

  myBeforeUpload = (file) => {
    beforeUpload(file);
    return false;
  }

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64Promise(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };
  
  handleCancel = () => this.setState({ previewVisible: false });

  onCancelModal = () => {
    this.props.form.resetFields(['acceptTC'])
    this.signaturePad.current.clear();
    this.setState({
      fileList: [],
      viewModal: false,
    });
  };

  onOkModal = () => {
    if(this.signaturePad.current.isEmpty() || this.state.fileList.length === 0){
      message.error(
        this.signaturePad.current.isEmpty()
          ? "¡No proporcionaste tu firma!"
          : "¡No subiste tu selfie!");
      return;
    }

    this.setState({
      viewModal: false,
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      sentDateToCreate,
      loadingBtnSumit,
      cities,
      termsandconditions,
      fileList,
      previewVisible,
      previewImage,
    } = this.state;
    const {
      getAllCountries: { GetAllCountries, loading: loadingCountries },
    } = this.props;

    const prefixSelector = getFieldDecorator("countryID", {
      initialValue: "1",
    })(
      <Select
        optionLabelProp="label"
        style={{ width: 70 }}
        showSearch
        loading={loadingCountries}
        onChange={this.onChangeCountries}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        placeholder="País"
        dropdownMatchSelectWidth={false}
      >
        {renderOptionsWithImg(GetAllCountries)}
      </Select>
    );

    const uploadButton = (
      <div>
        <Icon type={'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <BodyCreateUser>
        <div className="RightSide">
          <div className="GreenContainerCircle">
            <img
              alt=""
              src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fimage%203.png?alt=media&token=79fe89ed-4992-4caa-9150-4edd5e7a1e16"
            />
          </div>
        </div>
        <div className="LeftSide">
          {!sentDateToCreate ? (
            <Form hideRequiredMark id="saveAccount" className="formStyle">
              <Row>
                <Col xs={24} sm={24} md={4} lg={4} xl={4}></Col>
                <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                  <img
                    style={{ marginBottom: 78 }}
                    height={32}
                    alt="Chazi Logo"
                    src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FlogoAzul.png?alt=media&token=3e30a697-89bf-413c-b7c3-5c3e55478833"
                  />
                  <h1>
                    ¿Todo listo para una nueva aventura? Regístrate y crea tu
                    cuenta.
                  </h1>
                  <div>
                    <InputComponent
                      getFieldDecorator={getFieldDecorator}
                      decodator="names"
                      placeholder="Nombres"
                      message="Debe ingresar un nombre"
                      required
                    />

                    <InputComponent
                      getFieldDecorator={getFieldDecorator}
                      decodator="lastname"
                      placeholder="Apellidos"
                      message="Debe ingresar sus apellidos"
                      hasFeedback
                      required
                    />
                    <InputEmailComponent
                      getFieldDecorator={getFieldDecorator}
                      decodator="email"
                      placeholder="Correo electrónico"
                      message="El correo es incorrecto"
                      required
                    />
                    <Form.Item>
                      {getFieldDecorator("password", {
                        rules: [
                          {
                            required: true,
                            message: "Debe ingresar una contraseña",
                          },
                        ],
                      })(
                        <Input.Password
                          size="large"
                          style={{ width: "100%" }}
                          placeholder="Contraseña"
                        />
                      )}
                    </Form.Item>

                    <Form.Item>
                      {getFieldDecorator("phone", {
                        rules: [
                          {
                            required: true,
                            message: "El teléfono es incorrecto",
                          },
                        ],
                      })(
                        <Input
                          size="large"
                          placeholder="Teléfono"
                          addonBefore={prefixSelector}
                          style={{ width: "100%" }}
                        />
                      )}
                    </Form.Item>

                    <SelectComponent
                      getFieldDecorator={getFieldDecorator}
                      decodator="cityID"
                      options={renderOptions(
                        cities ||
                          (GetAllCountries &&
                            GetAllCountries.find(obj => obj.id === "1").Cities)
                      )}
                      message="Debe seleccionar una ciudad"
                      placeholder="Ciudad"
                      required
                    />
                  </div>

                  <Form.Item>
                    {getFieldDecorator("acceptTC", {
                      rules: [
                        {
                          required: true,
                          message: "Este campo es obligatorio.",
                        },
                      ],
                      valuePropName:"checked",
                      initialValue: this.state.checked
                    })(
                      <Checkbox required onChange={this.handleTyC}>
                        <a
                          href={
                            termsandconditions ||
                            (GetAllCountries &&
                              GetAllCountries.find(obj => obj.id === "1")
                                .termsandconditions)
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Acepto los términos y condiciones.
                        </a>
                      </Checkbox>
                    )}
                  </Form.Item>

                  <Button
                    type="submit"
                    loading={loadingBtnSumit}
                    className="btn-create-account"
                    onClick={this.handleSubmit}
                  >
                    Regístrate
                  </Button>
                  <div className="login">
                    ¿Eres Chazki o ya has completado el registro?
                    <a href="/"> Iniciar sesión</a>
                  </div>
                </Col>
              </Row>
              <Col xs={24} sm={24} md={4} lg={4} xl={4}></Col>
            </Form>
          ) : (
            <div className="message-afther-create">
              Tu cuenta ha sido creada. En breves momentos te enviaremos un
              correo electrónico para confirmar el registro de tu cuenta.
            </div>
          )}
        </div>
        <ModalContainer
          visible={this.state.viewModal}
          onOk={this.onOkModal}
          onCancel={this.onCancelModal}
          maskClosable={false}
          closable={false}
          style={{ top: 30 }}
        >
          <Paragraph>
            Al firmar y subir una selfie en la que sostienes tu documento de identidad, confirmas que has leído y aceptas los términos y condiciones aplicables a este servicio. Esta información es necesaria para verificar tu identidad y formalizar tu aceptación.
          </Paragraph>
          <Text strong>
            Por favor, proporciona tu firma
          </Text>
          <SignatureContainer>
            <SignaturePad
              redrawOnResize
              ref={this.signaturePad}
              height={140}
              //options={{backgroundColor: 'rgb(236, 236, 236)'}}
            />

          <Button
            className="bottom-clear"
            type="link"onClick={()=>this.signaturePad.current.clear()}
          >
            limpiar
          </Button>
          </SignatureContainer>
          <Text strong>
            Sube una selfie con tu identificación a la mano
          </Text>
            <Upload
              className="custom-upload"
              fileList={fileList}
              listType="picture-card"
              onChange={this.handleChangeUpload}
              beforeUpload={this.myBeforeUpload}
              onPreview={this.handlePreview}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
        </ModalContainer>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="img" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </BodyCreateUser>
    );
  }
}
const UploadCreateAccount = withFirebase(CreateAccount)
const FormCreateAccount = Form.create()(UploadCreateAccount);

export default compose(
  graphql(getAllCountries, {
    name: "getAllCountries",
    options: () => {
      return {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        variables: {},
      };
    },
  }),
  withApollo
)(FormCreateAccount);
