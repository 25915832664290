import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { FormItemInvisible } from "../Form";

class DateComponent extends React.Component {
  render() {
    const { getFieldDecorator, title, decodator, value, showTime } = this.props;

    // console.log("daySelected", daySelected);

    return (
      <div style={{ marginBottom: 20 }}>
        <FormItemInvisible
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          label={title}
        >
          {getFieldDecorator(decodator, {
            initialValue: (value && moment(value)) || undefined,
            rules: [
              {
                required: true,
                message: "Por favor, ingrese el nombre comercial",
              },
            ],
          })(<DatePicker showTime={showTime} />)}
        </FormItemInvisible>
      </div>
    );
  }
}

export default DateComponent;
