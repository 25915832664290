import React, { Component } from "react";
import styled from "styled-components";

const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 44px;

  @media only screen and (max-width: 800px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const ImageContainer = styled.div`
  width: 228px;
  height: 228px;
  border-radius: 114px;
  img {
    width: 228px;
    height: 228px;
  }
  @media only screen and (max-width: 800px) {
    width: 152px;
    height: 152px;
    border-radius: 121px;
    img {
      width: 152px;
      height: 152px;
    }
  }
`;

const TitleBlock = styled.div`
  margin-top: 24px;
  position: relative;
  text-align: center;
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
`;

const DescriptionBlock = styled.p`
  width: 312px;
  margin-top: 20px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */
  text-align: center;
  @media only screen and (max-width: 800px) {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */

    text-align: center;
  }
`;

class VerticalInformativeBlock extends Component {
  render() {
    const { image, title, description } = this.props;

    return (
      <BlockContainer>
        <ImageContainer>
          {image && <img alt={title} src={image} />}
        </ImageContainer>
        <TitleBlock>{title}</TitleBlock>
        <DescriptionBlock>{description}</DescriptionBlock>
      </BlockContainer>
    );
  }
}

export { VerticalInformativeBlock };
