import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { navigate } from "@reach/router";
import { message, Icon, Button } from "antd";
import axios from "axios";
import publicIp from "public-ip";
import { ApiService } from "../../services/ApiService.ts";
import { AuthService } from "../../services/AuthService.ts";
import { captureException } from "../../utils/sentry";
import Header from "./Components/Header";
import Jumbotron from "./Components/Jumbotron";
import Body from "./Components/Body";
import ReadyBlock from "./Components/ReadyBlock";
import Footer from "./Components/Footer";
import { SlideBar } from "./Components/styles";
import { getAllCountries } from "../../graphQl/queries/countries";

const authService = new AuthService();
const apiService = new ApiService();

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldCancel: false,
      changeScroll: false,
      widthSlidebar: false,
      currentCountryInfo: null,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this.getUser();
    window.addEventListener("scroll", this.handleScroll);
    this.getCountries();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    this.setState({ shouldCancel: true });
  }

  getCountryfromIP = () => {
    (async () => {
      const ip = await publicIp.v4();
      axios
        .get(`https://geolocation-db.com/json/${ip}`)
        .then(response => {
          this.setState({ currentCountryInfo: response.data });
          return response;
        })
        .catch(err => console.log(err));
    })();
  };

  getCountries = async () => {
    await this.getCountryfromIP();
    const {
      data: { GetAllCountries },
    } = await this.props.client.query({
      fetchPolicy: "network-only",
      query: getAllCountries,
    });
    this.setState({ countriesData: GetAllCountries });
  };

  handleScroll = event => {
    const { scrollTop } = event.srcElement.children[0];
    if (scrollTop > 1) {
      this.setState({ changeScroll: true });
    } else {
      this.setState({ changeScroll: false });
    }
  };

  login = () => {
    authService.login();
  };

  callApi = () => {
    apiService
      .callApi()
      .then(data => {
        this.setState({ api: data.data });
        message.success(
          "Api return successfully data, check in section - Api response"
        );
      })
      .catch(error => {
        captureException(error);
      });
  };

  renewToken = () => {
    console.log("token renewed");
    authService
      .renewToken()
      .then(user => {
        message.success("Token has been sucessfully renewed.");
        this.getUser();
      })
      .catch(error => {
        captureException("ERROR ->", error);
        console.log("error renew token->", error);
      });
  };

  logout = () => {
    authService.logout();
  };

  getUser = () => {
    try {
      authService
        .getUser()
        .then(user => {
          if (user) {
            navigate(`/dashboard/register/${user.profile.sub}`);
          } else {
            console.log("You are not logged in.");
          }
          if (!this.state.shouldCancel) {
            this.setState({ user });
          }
        })
        .catch(err => {
          console.log("error getUser", err);
        });
    } catch (err) {
      captureException(err);
    }
  };

  onClickheader = val => {
    const { widthSlidebar } = this.state;
    this.setState({ widthSlidebar: !widthSlidebar });
  };

  render() {
    return (
      <div>
        <SlideBar
          style={{
            width: this.state.widthSlidebar ? "100%" : 0,
          }}
        >
          <img
            className="iconImage"
            alt="chazi icon"
            src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2FchazkiIcon.png?alt=media&token=e6a5888e-ff41-4aad-90a9-61d09b862909"
          />

          <Icon
            className="onClickSlidebar"
            onClick={this.onClickheader}
            type="close"
            style={{ fontSize: 27, color: "#307fe2" }}
          />

          <div className="containerSlidebar">
            <a href="/">Afiliados</a>
            <a href="/">Servicios</a>
            <a href="/">Partners</a>

            <Button onClick={() => navigate(`/create-account`)}>
              Registrate
            </Button>
            <Button onClick={this.login}>Inicia Sesión</Button>
          </div>
        </SlideBar>
        <Header
          login={this.login}
          register={() => navigate(`/create-account`)}
          changeScroll={this.state.changeScroll}
          onClickheader={this.onClickheader}
        />
        <Jumbotron />
        <Body
          countriesData={this.state.countriesData}
          currentCountryInfo={this.state.currentCountryInfo}
        />
        <ReadyBlock />
        <Footer />
      </div>
    );
  }
}

export default compose(withApollo)(Landing);
