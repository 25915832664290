import React from "react";
import { Select, Form } from "antd";

class SelectComponent extends React.Component {
  render() {
    const {
      getFieldDecorator,
      decodator,
      options,
      value,
      disabled,
      mode,
      required,
      loading,
      onChange,
      placeholder,
    } = this.props;

    // console.log("daySelected", daySelected);

    return (
      <div>
        <Form.Item
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
        >
          {getFieldDecorator(decodator, {
            initialValue: undefined || value,
            rules: [
              {
                required,
                message: this.props.message,
              },
            ],
          })(
            <Select
              mode={mode}
              size="large"
              showSearch
              disabled={disabled}
              loading={loading}
              onChange={onChange}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder={placeholder}
            >
              {options}
            </Select>
          )}
        </Form.Item>
      </div>
    );
  }
}

export default SelectComponent;
