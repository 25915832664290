import gql from "graphql-tag";

const createPreaffiliateUser = gql`
  mutation createPreaffiliateUser(
    $names: String!
    $lastname: String!
    $email: String!
    $password: String!
    $cityID: Int!
    $phone: String!
    $urlLogin: String!
    $urlRedirect: String!
    $deviceIP: String!
    $deviceInfo: DeviceInfoInput!
    $versionTC: String!
    $urlFileSignature: String
    $urlFileSelfie: String
  ) {
    CreatePreaffiliateUser: createPreaffiliateUser(
      names: $names
      lastname: $lastname
      email: $email
      password: $password
      cityID: $cityID
      phone: $phone
      urlLogin: $urlLogin
      urlRedirect: $urlRedirect
      deviceIP: $deviceIP
      deviceInfo: $deviceInfo
      versionTC: $versionTC
      urlFileSignature: $urlFileSignature
      urlFileSelfie: $urlFileSelfie
    ) {
      message
      motive
      statusCode
    }
  }
`;

const verifyUserEmail = gql`
  mutation verifyUserEmail($token: String!) {
    VerifyUserEmail: verifyUserEmail(token: $token) {
      id
      names
      lastname
    }
  }
`;

export { createPreaffiliateUser, verifyUserEmail };
