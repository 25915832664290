import gql from "graphql-tag";

const getAllCountries = gql`
  query getAllCountries {
    GetAllCountries: getAllCountries {
      id
      name
      Cities {
        id
        name
      }
      urlFlag
      termsandconditions
      versionTC
      landing {
        requirements {
          urlImage
          description
        }
        documents {
          urlImage
          description
        }
      }
    }
  }
`;

export { getAllCountries };
