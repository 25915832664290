import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { CustomFormItemInputWithBorder } from "../Form";

const { RangePicker } = DatePicker;

class RangePickerWithBorderCompoment extends React.Component {
  render() {
    const {
      getFieldDecorator,
      title,
      decodator,
      value,
      required,
      placeholder,
      inLine,
      disabled,
      onChange,
      onOpenChange,
      disabledDate,
    } = this.props;

    // console.log("daySelected", daySelected);

    return (
      <div>
        <CustomFormItemInputWithBorder
          labelCol={{
            xs: { span: inLine ? 12 : 24 },
            sm: { span: inLine ? 12 : 24 },
            lg: { span: inLine ? 7 : 24 },
            xl: { span: inLine ? 7 : 24 },
          }}
          wrapperCol={{
            xs: { span: inLine ? 12 : 24 },
            sm: { span: inLine ? 12 : 24 },
            lg: { span: inLine ? 16 : 24 },
            xl: { span: inLine ? 16 : 24 },
          }}
          label={title}
        >
          {getFieldDecorator(decodator, {
            initialValue: (value && moment(value)) || undefined,
            rules: [
              {
                required,
                message: "Por favor, ingrese el nombre comercial",
              },
            ],
          })(
            <RangePicker
              placeholder={placeholder}
              disabled={disabled}
              onChange={onChange}
              onOpenChange={onOpenChange}
              disabledDate={disabledDate}
            />
          )}
        </CustomFormItemInputWithBorder>
      </div>
    );
  }
}

export default RangePickerWithBorderCompoment;
