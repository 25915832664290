import React from "react";
import { Input, Icon } from "antd";
import { CustomFormItemInputWithBorder } from "../Form";

class InputComponent extends React.Component {
  render() {
    const {
      getFieldDecorator,
      title,
      decodator,
      value,
      required,
      disabled,
      placeholder,
      suffix,
      addonAfter,
      allowClear,
      onChange,
      onPressEnter,
      refProp,
      size,
      onClick,
      validateStatus,
      help,
      prefix,
    } = this.props;

    // console.log("daySelected", daySelected);

    return (
      <div>
        <CustomFormItemInputWithBorder
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          label={title}
          validateStatus={validateStatus}
          help={help}
        >
          {getFieldDecorator(decodator, {
            initialValue: value || undefined,
            rules: [
              {
                required,
                message: ".",
              },
            ],
          })(
            <Input
              prefix={
                prefix && (
                  <Icon type={prefix} style={{ color: "rgba(0,0,0,.25)" }} />
                )
              }
              onClick={onClick}
              size={size || "large"}
              ref={refProp}
              onPressEnter={onPressEnter}
              onChange={onChange}
              allowClear={allowClear}
              disabled={disabled}
              placeholder={placeholder}
              suffix={suffix}
              addonAfter={addonAfter}
            />
          )}
        </CustomFormItemInputWithBorder>
      </div>
    );
  }
}

export default InputComponent;
