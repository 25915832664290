import styled from "styled-components"; // import { Button } from "antd";

const HeaderContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 80px;
  top: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  padding-left: 156px;
  padding-right: 156px;
  flex-direction: row;
  justify-content: space-between;

  && span {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
  }

  && .ant-btn-primary {
    background-color: transparent;
    width: 99px;
    height: 40px;
    padding: 0px;
  }

  && .blueFont span {
    color: #307fe2;
  }
  && .whiteFont {
    span {
      color: white;
    }
  }
  && .whiteFont-button {
    span {
      color: white;
    }
    border-color: white;
  }
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

const ResponsiveHeader = styled.div`
  @media only screen and (min-width: 800px) {
    display: none;
  }
  width: 100%;
  height: 68px;
  position: fixed;
  z-index: 5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 23px;
  padding-right: 23px;
  img {
    width: 24px;
    height: auto;
  }
`;

const SlideBar = styled.div`
  height: 100%;
  width: 0px;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: #f6f8fb;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;

  .iconImage {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  && a {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    transition: 0.5s;
    margin-bottom: 32px;
  }

  .containerSlidebar {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .onClickSlidebar {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  && .headerSlidebar {
    width: 100%;
    background-color: red;
    height: 68px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .ant-btn {
    width: 100%;
    height: 40px;
    background-color: #f6f8fb;
    border-color: #307fe2;
    span {
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #307fe2;
    }
    margin-bottom: 20px;
  }
`;

const JumbotronContainer = styled.div`
  width: 100%;
  height: 732px;
  position: relative;
  overflow: hidden;

  .backgroundRounded {
    width: 100%;
    height: 680px;
    background: #f6f8fb;
    border-radius: 0px 0px 80px 80px;
    padding-left: 156px;
    padding-right: 156px;
    display: flex;
    flex-direction: row;
  }

  && h1 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 72px;
    line-height: 76px;
    color: #595959;
    width: 450px;
  }

  && h2 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #8c8c8c;
  }

  && .titleJumbotron {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  && .circleGreen {
    position: absolute;
    top: -298px;
    right: -298px;
    background-color: #44d62c;
    width: 1030px;
    height: 1030px;
    border-radius: 515px;
    z-index: 1;
    overflow: hidden;

    img {
      height: 612px;
      position: absolute;
      bottom: 0px;
      left: 0px;
    }
  }

  @media only screen and (max-width: 800px) {
    height: 640px;
    .backgroundRounded {
      height: 640px;
      border-radius: 0px 0px 20px 20px;
      padding-left: 20px;
      padding-right: 20px;
    }

    && h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 36px;
      width: auto;
    }

    && h2 {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #8c8c8c;
    }
    && .titleJumbotron {
      position: relative;
      margin-top: 300px;
      height: auto;
      width: 100%;
    }

    && .circleGreen {
      position: absolute;
      top: -80px;
      right: -156px;
      background-color: #44d62c;
      width: 428px;
      height: 428px;
      border-radius: 515px;
      z-index: 1;
      overflow: hidden;

      img {
        height: 270px;
        position: absolute;
        bottom: 0px;
        left: 0px;
      }
    }
  }
`;

const BodyLanding = styled.div`
  padding-left: 156px;
  padding-right: 156px;
  margin-top: 84px;

  && h3 {
    font-family: Gilroy;
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 44px;
    color: #595959;
  }

  && h4 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #595959;
  }

  && .centerText {
    text-align: center;
  }

  && p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #8c8c8c;
  }

  @media only screen and (max-width: 800px) {
    padding-left: 20px;
    padding-right: 20px;

    && h3 {
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #595959;
    }

    && h4 {
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #595959;
    }

    && p {
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #8c8c8c;
    }
  }
`;

const ServicesBlock = styled.div`
  margin-top: 186px;
  && .circleBlue {
    background-color: #91d5ff;
    border-radius: calc(100% / 2);
  }

  && .servicesContainer {
    width: 100%;
    text-align: left;
  }

  && .divComlumn {
    display: flex;
    flex-direction: column;
    margin-left: 38px;
  }
  && .divRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 55px;
      height: 55px;
    }
  }

  @media only screen and (max-width: 800px) {
    margin-top: 68px;
    && .circleBlue {
      width: 256px;
      height: 256px;
      border-radius: 128;
      margin-bottom: 40px;
      img {
        width: 256px;
      }
    }
    && .servicesContainer {
      width: 100%;
      text-align: left;
    }
    && .divComlumn {
      display: flex;
      flex-direction: column;
      margin-left: 27px;
    }
    && .divRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 44px;
        height: 44px;
      }
    }
  }
`;

const RegisterBlock = styled.div`
  margin-top: 58px;
  .divRow {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center
    margin-bottom: 16px;
    p {
      margin-top: auto;
      margin-bottom: auto;
    }
    img {
      width: 72px;
      height: 72px;
      margin-right: 24px;
    }
  }
  .columnsContainer {
    margin-top: 44px;
  }


  @media only screen and (max-width: 900px) {
    margin-top: 72px;
    .divRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 44px;
        height: 44px;
        margin-right: 16px;
      }
    }
    .columnsContainer {
      margin-top: 20px;
    }
  }
`;

const OpinionsBlock = styled.div`
  margin-top: 84px;
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
  }

  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  && .photoProfile {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    background-color: #69c0ff;
    margin-right: 12px;
    overflow: hidden;
    position: relative;

    img {
      width: 36px;
      height: 36px;
      position: absolute;
      left: -1000%;
      right: -1000%;
      top: -1000%;
      bottom: -1000%;
      margin: auto;
      min-height: 100%;
      min-width: 100%;
    }
  }

  && .photoProfileContainer {
    display: flex;
    flex-direction: row;
  }
  @media only screen and (min-width: 900px) {
    .containerOpinion {
      margin-top: 44px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .containerDescription {
      position: relative;
      width: 588px;
      padding: 32px;
    }
    .photoProfileContainer {
      position: absolute;
      bottom: 32px;
    }

    .imageContainer {
      width: 348px;
      height: 348px;
      background-color: #69c0ff;
      overflow: hidden;
      position: relative;
      img {
        position: absolute;
        left: -1000%;
        right: -1000%;
        top: -1000%;
        bottom: -1000%;
        margin: auto;
        min-height: 100%;
        min-width: 100%;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .containerOpinion {
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
      margin-top: 16px;
    }

    .containerDescription {
      padding: 20px;
    }

    .imageContainer {
      width: 100%;
      height: 200px;
      background-color: #69c0ff;
      overflow: hidden;
      position: relative;
      img {
        position: absolute;
        left: -1000%;
        right: -1000%;
        top: -1000%;
        bottom: -1000%;
        margin: auto;
        min-height: 100%;
        min-width: 100%;
      }
    }
  }
`;

const AppBlock = styled.div`
  margin-top: 56px;
  height: 100%;

  .containerPhone {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 830px;
  }
  .circleBlue {
    z-index: 1;
    position: absolute;
    width: 552px;
    height: 552px;
    background-color: #69c0ff;
    border-radius: 276px;
  }
  img {
    height: 830px;
    position: relative;
    z-index: 2;
  }

  .colcontainer {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 830px;
    justify-content: center;
  }

  @media only screen and (max-width: 900px) {
    .containerPhone {
      height: 420px;
    }
    .circleBlue {
      z-index: 1;
      position: absolute;
      width: 256px;
      height: 256px;
      background-color: #69c0ff;
      border-radius: 128px;
    }
    img {
      height: 420px;
      position: relative;
      z-index: 2;
    }

    .colcontainer {
      display: flex;
      flex-direction: column;
      position: relative;
      height: auto;
      justify-content: center;
    }
  }
`;

const FaqBlock = styled.div`
  margin-top: 59px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .faqContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 744px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: #595959;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
  .faqItem {
    border-bottom: 1px solid #f0f0f0;
    height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      position: relative;
      margin-top: auto;
      margin-bottom: auto;
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
    }
  }
  @media only screen and (max-width: 900px) {
    .faqContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }

    .faqItem {
      border-bottom: 1px solid #f0f0f0;
      height: 51px;
      display: flex;
      h4 {
        position: relative;
        margin-top: auto;
        margin-bottom: auto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
`;
const ReadyBlockContainer = styled.div`
  margin-top: 152px;
  padding-top: 84px;
  height: 584px;
  background-color: #f6f8fb;
  text-align: center;
  overflow: hidden;

  && h3 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 52px;
    color: #595959;
  }
  && p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #8c8c8c;
  }

  .motoCityContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cityImg {
    position: relative;
    height: 290px;
  }
  .motoImg {
    position: absolute;
    img {
      width: 240px;
      height: auto;
    }
  }
  @media only screen and (max-width: 900px) {
    margin-top: 125;
    padding-top: 71px;
    height: 459px;

    && h3 {
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #595959;
    }
    && p {
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #8c8c8c;
    }
  }
`;

const FooterBlue = styled.div`
  background-color: #307fe2;
  padding-left: 156px;
  padding-right: 156px;
  padding-top: 84px;

  .rowContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    align-items: center;
    padding-right: 10px;
    img {
      margin-right: 12px;
    }
  }

  .email {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
  }
  .titleCountry {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
  }

  h3 {
    font-family: Gilroy;
    color: #91d5ff;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }

  p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: white;
  }

  a {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
  }

  .columnContainer {
    display: flex;
    flex-direction: column;
    a {
      margin-top: 20px;
    }
    img {
      margin-bottom: 10px;
    }
  }

  .collapseContainer {
    display: none;
  }

  .expandContainer {
    display: block;
  }

  .bottomFooter {
    height: 92px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .SocialContainer {
    img {
      margin-left: 9px;
      margin-right: 9px;
    }
  }
  .copyRight {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: white;
  }

  @media only screen and (max-width: 900px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 72px;
    padding-bottom: 20px;

    h3 {
      font-family: Gilroy;
      color: #91d5ff;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      margin-top: 16px;
    }

    p {
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: white;
      text-align: center;
    }

    .SocialContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 71px;
      margin-bottom: 23px;

      img {
        margin-left: 9px;
        margin-right: 9px;
      }
    }

    .expandContainer {
      display: none;
    }

    .collapseContainer {
      display: block;

      .ant-collapse-header {
        background-color: #307fe2;
      }
      .ant-collapse-content {
        background-color: #307fe2;
      }
      .ant-collapse {
        background-color: none;
        border: none;
        border-radius: 0px;
      }
      .ant-collapse-item {
        background-color: #307fe2;
        border-bottom: 2px solid #40a9ff;
      }
      .ant-collapse-header {
        padding: 12px 0;
        font-family: Gilroy;
        color: #91d5ff;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
      }
      .ant-collapse
        > .ant-collapse-item
        > .ant-collapse-header
        .ant-collapse-arrow {
        color: white;
      }
    }

    .columnContainer {
      display: flex;
      flex-direction: column;
      a {
        margin-bottom: 20px;
        font-family: Gilroy;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
      }
    }
  }
`;

export {
  HeaderContainer,
  SlideBar,
  ResponsiveHeader,
  JumbotronContainer,
  BodyLanding,
  ServicesBlock,
  RegisterBlock,
  OpinionsBlock,
  AppBlock,
  FaqBlock,
  ReadyBlockContainer,
  FooterBlue,
};
